import * as Sentry from "@sentry/browser"
import { ready } from "#js/components/utils"

const config = Object.assign(
  globalThis.sentryConfig || {},
  {
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.captureConsoleIntegration({ levels: ["error"] }),
      Sentry.replayIntegration(),
    ],
  },
)

ready(() => {
  if (config.dsn) {
    Sentry.init({
      ...config,
    })
    globalThis.Sentry = Sentry
  }
})
